import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private errorLog: Array<object> = [];
  private connectionLog: Array<object> = [];
  private outboundLog: Array<object> = [];
  private agentsLog: Array<object> = [];
  private applicationAction: Array<object> = [];

  constructor() { }

  logError(errorType: string, errorMessage: any) {
    try {
      let newError = {
        date: new Date().toLocaleString('en-US'),
        type: errorType.trim(),
        error: errorMessage
      };
      this.errorLog = [ newError, ...this.errorLog ];
      console.error('Error!!', errorType, errorMessage);
      console.error('Error log: ', this.errorLog);
    } catch (e) {
      console.error('Problem adding error to error log', e);
    }
  }

  logConnection( connectionType: string )  {
    try {
      let newConnection = {
        date: new Date().toLocaleString('en-US'),
        type: connectionType
      };
      this.connectionLog = [ newConnection, ...this.connectionLog ];
    } catch (e) {
      console.error('Problem logging connection: ', e);
    }
  }

  // tslint:disable-next-line:typedef
  logOutboundSearches( search: string, result: object ) {
    try {
      const newOutbound = {
        date: new Date().toLocaleString('en-US'),
        search: search,
        result: result
      };
      this.outboundLog = [ newOutbound, ...this.outboundLog ];
    } catch (e) {
      console.error('Problem logging outbound search: ', e);
    }
  }

  // tslint:disable-next-line:typedef
  logAgentsLoggedIn( agent: object ) {
    try {
      let newAgentLoggedIn = {
        date: new Date().toLocaleString('en-US'),
        agent: agent
      };
      this.agentsLog = [ newAgentLoggedIn, ...this.agentsLog ];
    } catch (e) {
      console.error('Problem logging agent logged in: ', e);
    }
  }

  // @ts-ignore
  // tslint:disable-next-line:typedef
  getLogs() {
    try {
      return  [
        {
          type: 'Errors',
          data: JSON.stringify( this.errorLog )
        },
        {
          type: 'Connections',
          data: JSON.stringify(this.connectionLog)
        },
        {
          type: 'outbounds',
          data: JSON.stringify(this.outboundLog)
        },
        {
          type: 'Agents',
          data: JSON.stringify(this.agentsLog)
        }
      ];
    } catch (e) {
      console.error('Problem getting logs: ', e);
    }
  }
}
