import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// RX JS
import { Observable, Subject, timer, BehaviorSubject } from 'rxjs';
import { filter, tap, map, take, switchMap, retryWhen, repeat, distinctUntilChanged } from 'rxjs/operators';

// Websocket - rxjs

import { webSocket } from 'rxjs/webSocket';


// Websocket settings
const address = environment.websocket;
const reconnectDelay = 1000; // ms

// Service for logging
import { LoggingService } from '../general-apis/logging.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  // Websocket status
  private status$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private attemptNr = 0;
  private ws: any;

  // Websocket message
  public messages$: Subject<any> = new Subject<any>();

  constructor(private log: LoggingService) {}

  // Get connection status
  get connectionStatus$(): Observable<boolean> {
    return this.status$.pipe(distinctUntilChanged());
  }

  // Create connection to socket
  createConnection() {
    this.log.logConnection('Socket Connected ' + JSON.stringify(this.status$));
    // tslint:disable-next-line:no-console
    console.info(new Date() + ' Connection | connected');
    const retryConnection = switchMap( () => {
      this.status$.next(false);
      this.attemptNr = this.attemptNr + 1;
      // console.error(new Date() + ' Connection | connected');
      this.log.logConnection('Socket Disconnected ' + JSON.stringify(this.status$));
      return timer(reconnectDelay);
    });
    const openObserver = new Subject<Event>();
    openObserver.pipe(map((_) => true)).subscribe(this.status$);
    const closeObserver = new Subject<CloseEvent>();
    closeObserver.pipe(map((_) => false)).subscribe(this.status$);
    this.ws = webSocket<any>({
      url: `${address}?hash=${localStorage.getItem('hash')}`,
      openObserver,
      closeObserver,
    });
    this.ws.pipe(retryWhen((errs) => errs.pipe(retryConnection, repeat()))).subscribe(this.messages$);
  }

  closeConnection() {

  }

  // Send and receive messages
  message(message: any) {
    this.connectionStatus$.pipe(
        filter( status => status ),
        tap( () => this.ws.next(message)),
        take(1)
    ).subscribe();
    // this.log.logConnection('Socket Action | Connection status: ' + JSON.stringify(message));
  }
}

