import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Services
import { WebsocketService } from "./services/chat/websocket.service";

// Angular cdk
import { Overlay } from '@angular/cdk/overlay';

// Third party
import { NgxMaskModule, IConfig } from 'ngx-mask';
// @ts-ignore
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import {ScrollingModule} from '@angular/cdk/scrolling';

import {TimeagoClock, TimeagoModule} from 'ngx-timeago';
import {MyClock} from './services/general-apis/clock.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TimeagoModule.forRoot(
      {clock: {provide: TimeagoClock, useClass: MyClock}}
    ),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    NgxMaskModule.forRoot(),
    ScrollingModule,
  ],
  providers: [
    WebsocketService,
    Overlay
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
